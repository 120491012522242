import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorParser } from '@app/classes';
import { extractOverlapErrors } from '@app/modules/time-tracking/functions/extract-overlaps';
import { NotifyService } from '@app/services';

@Injectable()
export class TimesheetOverlapErrorHandlingService {
    constructor(private notifyService: NotifyService) {}

    handleHttpError(error: HttpErrorResponse): { overlapErrors: string[] } {
        // We extract the overlap errors from the error message
        const overlapErrors = extractOverlapErrors(error.error);

        if (overlapErrors.length === 0) {
            this.notifyService.error(ErrorParser.parse(error));
        }

        return { overlapErrors };
    }
}
