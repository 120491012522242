import { ProjectOwnershipService } from '@time-tracking/services/project-ownership.service';
import { EmployeeHourTypesService } from './employee-hour-types.service';
import { TimeTrackingMenuService } from './menus/time-tracking-menu';
import { ProjectEmployeeAssignmentService } from './project-employee-assignment.service';
import { ReportDownloadService } from './report-download.service';
import { EmployeeReportsIndexTable } from './tables/employee-report/employee-report.table';
import { EmployeesAssignedToTimeTracking } from './tables/employees-assigned-to-time-tracking/employees-assigned-to-time-tracking.tables';
import { MyProjectsIndexTable } from './tables/my-projects-index/my-projects-index.table';
import { MyTimesheetsIndexTable } from './tables/my-timesheets-index/my-timesheets-index.table';
import { ProjectsIndexTable } from './tables/projects-index/projects-index.tables';
import { TimesheetsIndexTable } from './tables/timesheets-index/timesheets-index.table';
import { TimesheetEntryService } from './timesheet-entry.service';
import { TimesheetOverlapErrorHandlingService } from './timesheet-overlap-error-handling.service';

const Services = [
    ProjectsIndexTable,
    EmployeesAssignedToTimeTracking,
    EmployeeHourTypesService,
    ProjectEmployeeAssignmentService,
    MyProjectsIndexTable,
    ProjectOwnershipService,
    TimeTrackingMenuService,
    ReportDownloadService,
    TimesheetOverlapErrorHandlingService,
];

export {
    EmployeeHourTypesService,
    EmployeeReportsIndexTable,
    EmployeesAssignedToTimeTracking,
    MyProjectsIndexTable,
    MyTimesheetsIndexTable,
    ProjectEmployeeAssignmentService,
    ProjectOwnershipService,
    ProjectsIndexTable,
    ReportDownloadService,
    Services,
    TimesheetEntryService,
    TimesheetOverlapErrorHandlingService,
    TimesheetsIndexTable,
    TimeTrackingMenuService,
};
